<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img
            :src='"https://gravatar.loli.net/avatar/"+$md5($cookies.get("email"))+"?d=wavatar"'
            class="c-avatar-img "
            onerror="javascript:this.src='/img/avatar.jpeg';this.onerror = null"
          />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Account</strong>
    </CDropdownHeader>
    <CDropdownItem @click="exitGodMode" v-if="$cookies.get('god_mode')">
      <CIcon name="cib-composer" /> Exit God Mode
    </CDropdownItem>
    <CDropdownItem @click="$router.push('/auth/logout')">
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return { 
      itemsCount: 42
    }
  },
  methods: {
    exitGodMode: function () {
      this.$axios.get("user/exit-god-mode", {})
        .then((response) => {
          if (response.data.code === 200) {
            console.log(response.data.msg);
            this.$forceUpdate();
          }
        });
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>