<template>
  <CFooter :fixed="false">
    <div class="mx-auto">
      <span class="ml-1">Copyright &copy; 2020-{{ new Date().getFullYear() }} <CLink href="https://user.airmy.life/">AirmyLife ☁️</CLink>.All Rights Reserved.
        | <CIcon name="cib-huawei" /> | <CIcon name="cib-cisco" /> | <CIcon name="cib-cloudflare" /> | <CIcon name="cib-amazon-aws" /> | <CIcon name="cib-dell" /> | <CIcon name="cib-hp" /></span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: "TheFooter"
};
</script>
