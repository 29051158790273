export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Index',
        to: '/index',
        icon: 'cil-speedometer',
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Node']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Node List',
        to: '/node',
        icon: 'cil-flight-takeoff'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Traffic Log',
        to: '/traffic-log',
        icon: 'cil-drop'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Relay Rule',
        to: '/relay',
        icon: 'cil-transfer'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Account']
      },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Edit Info',
      //   to: '/account/info',
      //   icon: 'cil-pencil'
      // },
      {
        _name: 'CSidebarNavItem',
        name: 'Purchase Record',
        to: '/account/bought',
        icon: 'cil-basket'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['ADMIN']
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'User',
        route: '/admin/user',
        icon: 'cil-puzzle',
        items: [
          {
            name: 'List',
            to: '/admin/user/list'
          }
        ]
      },
      {
        _name: 'CSidebarNavDivider',
        _class: 'm-2'
      },
      {
        icon: 'cil-star',
        name: 'Logout',
        to: '/auth/login'
      }
    ]
  }
]